import React from "react";
import PropTypes from "prop-types";
import SEO from "../components/functional/seo";
import DesignStory from "../components/molecules/design-story";
import MediaBlock from "../components/organisms/media-block";
import MediaCarousel from "../components/organisms/media-carousel";

function DesignRelatedGroup({ props }) {
  const designProps = props;
  return (
    <div className="design__content design__content--media">
      <SEO title="Design System - Related Group" />
      <h2>Related Group</h2>
      <p className="design__text p2">
        Related Groups can be used to display related pages or products.
      </p>
      <div className="design__stories">
        <DesignStory
          title="Related Group"
          component={MediaCarousel}
          subComponent={MediaBlock}
          type="carousel"
          props={designProps}
        />
      </div>
    </div>
  );
}

DesignRelatedGroup.propTypes = {
  props: PropTypes.objectOf(PropTypes.any),
};

DesignRelatedGroup.defaultProps = {
  props: {},
};

export default DesignRelatedGroup;
